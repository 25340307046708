import { graphql, readInlineData } from 'react-relay';
import { trackAbTestV2Variant } from 'dibs-buyer-layout/exports/clientABTestV2';
import {
    BEST_SELLERS_COLLECTION,
    NET_PRICE_15_FILTER_TEST_NAME,
    PERSONALIZED_RERANK_TEST_NAME,
    ITEM_TILE_SHORTER_HEIGHT,
    USER_ENGAGEMENT_BOOST_TEST_NAME,
    ITEM_PRICE_IN_SELLABILITY,
    isUserEngagementBoostAbTestControl,
    activeAbTests,
} from '../../utils/abTestHelper';
import { pageTypeConstants } from '../../constants/pageTypeConstants';
import { SortMap } from '../../constants/sbConstants';
import { isUserInEurope } from 'dibs-regional-info/src/regionalInfoHelpers';
import { ITEM_REGION_ON_TIlE } from '../../shared/hooks/useShowItemLocationBadge';
import { trackEligibleForRerankEvent } from '../../utils/tracking/searchBrowse/addPersonalizationTrackingData';
import { getEngagedItems } from 'dibs-buyer-layout/exports/engagedItems';

import { SbSharedTrackingFunctions_itemSearch$key } from './__generated__/SbSharedTrackingFunctions_itemSearch.graphql';
import { trackEvent, eventNameConstants } from 'dibs-tracking';

const itemSearchFragment = graphql`
    fragment SbSharedTrackingFunctions_itemSearch on ItemSearchQueryConnection @inline {
        trackedAbTests
    }
`;

type PersonalizedRerankProps = {
    pageType?: string | null;
    sortValue?: string | null;
    isEligibleForRerank?: boolean | null;
};
export const personalizedRerankTracking = ({
    pageType,
    sortValue,
    isEligibleForRerank,
}: PersonalizedRerankProps): void => {
    // This if should be the same as
    // https://github.com/1stdibs/dibs-graphql/blob/release/src/schema/inventory/itemSearch/solrQueries/generateResultQuery.ts#L228
    if (
        sortValue === SortMap.relevance ||
        sortValue === SortMap.buyPageRelevance ||
        (pageType !== pageTypeConstants.BUY && sortValue === SortMap.recommended)
    ) {
        trackAbTestV2Variant(PERSONALIZED_RERANK_TEST_NAME);
    }

    if (isEligibleForRerank) {
        trackEligibleForRerankEvent();
    }
};

export const bestSellersSellabilityTracking = ({
    displayUriRef,
    pageType,
}: {
    displayUriRef: string;
    pageType: string;
}): void => {
    if (pageType === pageTypeConstants.COLLECTION && displayUriRef.includes('/best-sellers/')) {
        trackAbTestV2Variant(BEST_SELLERS_COLLECTION);
    }
};

export const itemRegionOnTileTracking = (): void => {
    const isInEurope = isUserInEurope();
    if (isInEurope) {
        trackAbTestV2Variant(ITEM_REGION_ON_TIlE);
    }
};

export const newNetPriceFilterTracking = ({ isTrade }: { isTrade?: boolean | null }): void => {
    if (isTrade) {
        trackAbTestV2Variant(NET_PRICE_15_FILTER_TEST_NAME);
    }
};
export const itemTileShorterHeightTracking = (): void => {
    trackAbTestV2Variant(ITEM_TILE_SHORTER_HEIGHT);
};

export const userEngagementBoostTracking = (resultItemIds: string[]): void => {
    const engagedItems = getEngagedItems();

    // SEARCH-3024 debug tracking
    // In AB test control we have sent engagedItems, but did not got `controlEngagedItemIds` in response
    if (isUserEngagementBoostAbTestControl() && engagedItems.length && !resultItemIds.length) {
        trackEvent({
            eventName: eventNameConstants.EVENT_DEBUG,
            interaction_type: 'no control items',
            step_interaction_name: 'control',
        });
    }
    if (!engagedItems.length || !resultItemIds.length) {
        return;
    }
    const engagedItemsMap = engagedItems.reduce(
        (acc, itemId) => ({ ...acc, [itemId]: true }),
        <Record<string, boolean>>{}
    );
    const hasEngagedItemsInResult = resultItemIds.some(
        resultItemId => engagedItemsMap[resultItemId]
    );
    if (hasEngagedItemsInResult) {
        trackAbTestV2Variant(USER_ENGAGEMENT_BOOST_TEST_NAME);
    }
};

export const trackActiveAbTests = (
    itemSearchRef: SbSharedTrackingFunctions_itemSearch$key
): void => {
    const itemSearch = readInlineData(itemSearchFragment, itemSearchRef);
    for (const abTest of activeAbTests) {
        if (itemSearch.trackedAbTests?.includes(abTest)) {
            trackAbTestV2Variant(abTest);
        }
    }
};

export const itemPriceInSellabilityTracking = ({
    pageType,
    sortValue,
}: {
    pageType?: string | null;
    sortValue?: string | null;
}): void => {
    if (
        (pageType === pageTypeConstants.SEARCH && sortValue === SortMap.relevance) ||
        sortValue === SortMap.recommended
    ) {
        trackAbTestV2Variant(ITEM_PRICE_IN_SELLABILITY);
    }
};
